import { css } from '@emotion/react'
import React, { memo, useState } from 'react'
import { AppLink } from '~/components/AppLink'
import type { CarouselMediaData } from '~/modules/auth/components/AgentCarouselSubscriptionCheck/MediaData'
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material'
import { MobileStepper, Button } from '@mui/material'
import { oneGridArea } from '~/modules/AppLayout/FlexGridCss'
import { useInterval } from 'react-use'

const stepperCss = css`
  background: transparent;
  color: transparent;
  position: absolute;
  top: 40%;
  width: 100%;
  z-index: 1000;
`

export const MediaBanner = memo<
  ReactProps<{ resources: CarouselMediaData[]; animation?: 'fade' | 'slide' }>
>(function MediaBanner(props) {
  const [activeStep, setActiveStep] = useState(0)
  const goNext = () => {
    setActiveStep(prevActiveStep => (prevActiveStep + 1 === maxSteps ? 0 : prevActiveStep + 1))
  }

  const goPrev = () => {
    setActiveStep(prevActiveStep => (prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1))
  }

  const maxSteps = props.resources.length
  const selectedItem = props.resources[activeStep]

  useInterval(() => {
    if (selectedItem.videoEmbeded?.[0]) return
    goNext()
  }, 5000)

  return (
    <div
      css={css`
        width: 75%;
        aspect-ratio: 1.777/1;
        border: 2px solid #aaaaaa;
        box-shadow: 0px 0px 20px 7.5px #555555;
      `}
    >
      <div
        css={css`
          position: relative;
          ${oneGridArea.parentGrid('one')};
          width: 100%;
          height: 100%;
          & > * {
            ${oneGridArea.childGridItem('one')};
          }
        `}
      >
        <MobileStepper
          variant='text'
          css={stepperCss}
          steps={maxSteps}
          position='static'
          activeStep={activeStep}
          nextButton={
            <Button
              color='error'
              onClick={goNext}
            >
              {<KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button
              color='error'
              onClick={goPrev}
            >
              {<KeyboardArrowLeft />}
            </Button>
          }
        />
        <div css={css``}>
          {selectedItem.image?.[0] ? (
            <ImageItem_ resource={selectedItem.image?.[1]} />
          ) : selectedItem.videoEmbeded?.[0] ? (
            <VideoEmbeded_ resource={selectedItem.videoEmbeded?.[1]} />
          ) : selectedItem.goSomewhere?.[0] ? (
            <GoSomewhere_
              background={selectedItem.goSomewhere?.[1]}
              to={selectedItem.goSomewhere?.[2]}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
})

const fadeinCss = css`
  animation: fadeIn 1s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const ImageItem_ = memo<ReactProps<{ resource: string }>>(function ImageItem_(props) {
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        background: url('${props.resource}');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        ${fadeinCss}
      `}
    ></div>
  )
})

const VideoEmbeded_ = memo<ReactProps<{ resource: string }>>(function VideoEmbeded_(props) {
  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.81%;
        /* 16:9 */
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      `}
    >
      <iframe
        src={props.resource}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;'
        allowFullScreen
      />
    </div>
  )
})

const GoSomewhere_ = memo<ReactProps<{ background: string; to: string }>>(function GoSomewhere_(
  props,
) {
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        animation: fadeIn 5s;
        ${fadeinCss}
      `}
    >
      <AppLink
        target='_blank'
        href={props.to}
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        <img
          css={css`
            width: 100%;
            height: 100%;
          `}
          src={props.background}
        />
      </AppLink>
    </div>
  )
})
