import { css } from '@emotion/react'
import React, { memo } from 'react'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { flex } from '~/modules/AppLayout/FlexGridCss'

const buttonCss = css`
  padding: 16px 64px;
  border-radius: 8px;
  font-size: 1.2rem;
  border: 2px solid gray;
  background: linear-gradient(rgba(56, 160, 194, 1), rgba(44, 99, 156, 1));
  border: #ffffff;
  color: white;
  &:hover {
    transition: 0.3s;
    background: linear-gradient(rgba(88, 160, 194, 1), rgba(44, 120, 156, 1));
  }
`

export const LoginView = memo(function LoginView() {
  return (
    <div css={flex.v.allCenter}>
      <UserAvatarAsDialogButton
        loginAsText={'會員專屬功能，請先登入'}
        rootCSS={buttonCss}
      />
    </div>
  )
})
